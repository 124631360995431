<template>
  <v-container
      fill-height
      fluid
      pa-0 ma-0 pb-0
  >
    <v-row fill-height mb-2 style="align-self: flex-end">
      <v-col cols="12" class="pb-0 pl-6 caption-container">
        <v-card color="rgba(0,0,0,.3)" class="pa-2">
          <span class="white--text caption"><slot/></span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import BasicComponent from "@/global/BasicComponent";

@Component
export default class ImageCaption extends BasicComponent {
  @Prop(String) value;

}
</script>

<style scoped lang="scss">
.caption-container {
  margin-bottom: 50px;

  .caption {
    font-size: 1em !important;
  }
}

</style>
