<template>
  <div class="home">
    <div class="header-container">
      <div class="lights-chain"></div>
      <!--<img src="@/assets/singers.svg" class="singers">-->
      <img src="@/assets/enough-distance.svg" class="enough-distance">
      <img src="@/assets/header-new2.svg" class="header-text">
    </div>
    <v-container>
      <v-row justify="center">
        <v-col cols="auto" justify="center">
          <!--<p class="banner">Mach mit beim 1. Salzburger Kinder- & Jugendrechte Song Contest 2022</p>-->
          <p class="project-short-description">Ein Projekt der Kinder- und Jugendanwaltschaft Salzburg in Kooperation
            mit Akzente
            Salzburg, Spektrum und dem Verein zur Förderung der Kinderrechte</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Dein Song ist gefragt!</h2>
          <p>WIR WOLLEN EUCH HÖREN, WIR WOLLEN WISSEN, WAS EUCH
            <b>GLÜCKLICH</b> MACHT, ABER AUCH <b>WAS EUCH SORGEN BEREITET</b>. WIR WOLLEN HÖREN, WIE IHR <b>EURE
              ZUKUNFT</b> HABEN WOLLT. WAS IST EUCH <b>WICHTIG</b>?</p>
          <p>Und zwar lautstark, unüberhörbar und frei von der Leber weg. Verpackt eure Gedanken, eure Wünsche und
            Hoffnungen in ein Lied. Erzählt, wie ihr die Welt seht, was wir verändern müssen und was ihr euch für die
            Zukunft wünscht. Oder verratet uns, was euch in den letzten Jahren geholfen hat, besser durch den Alltag zu
            kommen. Wer oder was hat euch <u>Kraft gegeben</u>? </p>
          <p><b>Ihr habt etwas zu sagen und wir wollen es hören!</b></p>
          <p>
            Deshalb laden wir euch ganz herzlich dazu ein, gemeinsam mit eurer Klasse, mit Freunden oder auch alleine
            ein Lied zu schreiben. Werdet Song Contest-Pioniere und macht mit beim 1. Salzburger Kinder- & Jugendrechte
            Song Contest.
          </p>
          <!--<p><b>
            DU MACHST MUSIK UND / ODER SCHREIBST EIGENE SONGS? DIE WELT UM DICH HERUM IST DIR WICHTIG UND DU HAST EINE
            MEINUNG DAZU?
          </b></p>
          <p>Dann schick uns dein Lied, <b>weil deine Stimme zählt!</b></p>-->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Das gibt es zu gewinnen ...</h2>
          <p>
            Aus allen Einreichungen kürt die Jury die Siegersongs. Diese gewinnen allesamt einen <b>Song-Produktionsworkshop</b>
            mit professionellen Tontechniker*innen und Musiker*innen. Ergebnis ist ein <b>professionelles Playback</b>
            für euren
            Song, den ihr am 15. November <b>live im OVAL – Die Bühne im EUROPARK</b> einem Publikum präsentieren
            dürft.
            Hinterher
            bekommt ihr ein <b>Video</b> von eurem Auftritt und eine CD mit allen Siegersongs. Darüber hinaus
            warten <b>tolle
            Gutscheine und Sachpreise</b> auf euch. Wir freuen uns auf eure Einreichungen!
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Und so funktioniert es</h2>
        </v-col>
        <v-col cols="12" class="steps">
          <p>
            <v-icon>edit</v-icon>
            Song schreiben
          </p>
          <p>
            <v-icon>psychology</v-icon>
            Einstudieren
          </p>
          <p>
            <v-icon>videocam</v-icon>
            Über Handy, PC, Tablet oder Laptop aufnehmen
          </p>
          <p>
            <v-icon>assignment</v-icon>
            Anmeldeformular samt Datenschutzerklärung online ausfüllen
          </p>
          <p>
            <v-icon>email</v-icon>
            MP3 + Anmeldeformular + Liedtext einsenden
          </p>
          <p>
            <v-icon>mic</v-icon>
            Bei Auswahl einen Song-Produktionsworkshop mit professionellen Tontechniker*innen und Musiker*innen
            erleben
          </p>
          <p>
            <v-icon>celebration</v-icon>
            Am 15.11. im OVAL – Die Bühne im EUROPARK mit professionellem Playback auftreten
          </p>
          <p>
            <v-icon>emoji_events</v-icon>
            Applaus genießen und Preis abholen
          </p>
          <p>
            <v-icon>live_tv</v-icon>
            Video vom eigenen Auftritt und Siegersongs erhalten
          </p>

          <!--<p>Song schreiben</p>
          <p>Einstudieren</p>
          <p>Über Handy, PC, Tablet oder Laptop aufnehmen</p>
          <p>Anmeldeformular samt Datenschutzerklärung online ausfüllen</p>
          <p>MP3 + Anmeldeformular + Liedtext einsenden</p>
          <p>Bei Auswahl einen Song-Produktionsworkshop mit professionellen Tontechniker*innen und Musiker*innen
            erleben</p>
          <p>Am 15.11. im OVAL – Die Bühne im EUROPARK mit professionellem Playback auftreten</p>
          <p>Applaus genießen und Preis abholen</p>
          <p>Video vom eigenen Auftritt und Siegersongs erhalten</p>-->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Wer mitmachen kann</h2>
          <p>Kinder und Jugendliche bis 18 Jahre, <b>egal</b> ob Schulklassen, Solist*innen, Bands & Chöre aus dem
            Bundesland Salzburg und dem Grenzland zu Bayern.</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Wichtige Infos zur Einreichung</h2>
          <ul>
            <li>Die Musik und der Text müssen von dir/euch stammen. Bei Einreichungen aus der Volksschule und Unterstufe
              im Rahmen des Musikunterrichts dürft ihr euch durch eine Lehrkraft unterstützen lassen.
            </li>
            <li>Der Text sollte eine der folgenden Fragen aufgreifen: Was ist euch wirklich wichtig? Was ist dir
              wichtig? Was wünscht ihr euch für die Zukunft? Was macht euch Sorgen und was macht euch stark? Wo laufen
              eurer Meinung nach Dinge falsch, die ihr verändern wollt, oder die Erwachsene verändern sollten? Wer oder
              was gibt euch Halt – auch im Hinblick auf die vergangenen zwei Jahre?
            </li>
            <li>Das Lied sollte eine maximale Länge von 3 Minuten haben und muss innerhalb der Einreichfrist bei uns
              eintreffen.
            </li>
          </ul>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>So kannst du deinen Song einreichen</h2>
          <p>Nimm deinen Song über Handy, PC, Tablet oder Laptop auf und sende ihn an <a
              href="mailto:maria.jaeger@salzburg.gv.at">maria.jaeger@salzburg.gv.at</a>. Die
            Datei sollte maximal 10 MB haben. Sobald eure Einreichung angekommen ist, erhaltet ihr eine Bestätigung.
            Gerne könnt ihr uns im Vorfeld schon schreiben, dass ihr mitmachen werdet. </p>

          <p><b>EINREICHUNG PER E-MAIL</b></p>
          <p>Die vollständige Einreichung besteht aus:</p>
          <ol>
            <li>Song als mp3 oder Video</li>
            <li>Ausgefülltes, unterzeichnetes <a href="/Anmeldeformular.pdf" target="_blank"
                                                 download>Anmeldeformular</a></li>
            <li>Liedtext</li>
            <li>Unterzeichnete <a href="/Datenschutzerklaerung.pdf" target="_blank" download>Datenschutzbestimmungen</a>
            </li>
          </ol>
          <p>Mit der Unterzeichnung erklärst du dich damit einverstanden, dass das Lied als Audio und Video sowie
            Interviews, die im Rahmen der Aufführung entstehen, über unsere Kanäle für einen begrenzten Zeitraum
            veröffentlicht werden dürfen. Schulklassen bitten wir darum, im Vorfeld mit den Eltern abzuklären, ob sie
            einverstanden sind.</p>

          <p><b>PER POST</b></p>
          <p>Sende den Song auf einem Datenträger (CD, USB-Stick, etc.) per Post an die Kinder- und Jugendanwaltschaft
            Salzburg, Fasaneriestraße 35, 5020 Salzburg und lege das ausgefüllte und unterzeichnete Anmeldeformular, die
            unterzeichnete Datenschutzbestimmung und den Liedtext bei. Natürlich kannst du uns den Datenträger und die
            Dokumente auch persönlich vorbeibringen.</p>

          <p><em>Unsere Öffnungszeiten:</em></p>
          <p>Mo, Di, Do: 09.00 - 13.00 & 14.00 - 16.00 Uhr<br>
            Mi: 14.00 - 16.00 Uhr<br>
            Fr: 09.00 - 13.00 Uhr<br>
            & nach Vereinbarung
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Einreichfrist</h2>
          <p><em>15.07.2022</em></p>
          <p>Nicht vergessen: Song rechtzeitig schicken! Wir sind gespannt auf eure Beiträge.</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Unsere Jury</h2>
          <v-row class="jury">
            <Jury name="Christoph Rabl" description="Musiker und Geschäftsführer der Kulturplattform Traumfänger"
                  quote="‚Earth’ without ‚art’ is just ‚Eh’. Mit Musik können wir bewegen, ermutigen und von uns erzählen. Ich freu mich auf alle Einsendungen und wünsch euch viel Freude und Energie beim Komponieren."/>
            <Jury name="Andrea Holz-Dahrenstaedt" description="Kinder- und Jugendanwältin Salzburg"
                  quote="Musik ist ein Wunder und heilt so manche Wunden. Vor allem ist sie für mich eine wunderbare Quelle, um Freude und Lebensenergie zu tanken. Sowohl beim leidenschaftlichen (Mit)singen oder aber einfach beim Zuhören, so dass selbst ein verklungenes Stück noch lange in meinem Herzen weitersingt. Ich wünsche euch, dass ihr genau das mit eurem Lied erlebt."/>
            <Jury name="Romy Seidl" description="Moderatorin und Redakteurin ORF Salzburg"
                  quote="Besser geht es wohl nicht! Singen für Kinder- und Jugendrechte!"/>
            <Jury name="Christa Musger"
                  description="Fachinspektorin für Musik und Instrumentalmusik der Bildungsdirektion für Salzburg"
                  quote="Durchs Singen und Musizieren werden große Probleme kleiner."/>
            <Jury name="Gerhard Hofbauer" description="Verein zur Förderung der Kinderrechte"
                  quote="Dass die eingereichten Songs echt und authentisch sind, ist mir wichtiger, als dass sie perfekt sind."/>
            <Jury name="Wolfgang Schramml" description="Produktion, Regie, Geschäftsführung Sonic Flow"
                  quote="Musik ist ein großes Geschenk im Leben eines Menschen, selbst musizieren zu können ein noch größeres."/>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <p class="banner" style="margin-top: 2em;">Das war der Kinder- und Jugendrechte Song Contest 2022</p>

          <v-img :src="require('@/assets/bilder/Gruppenbild Song Contest 2022.jpg')"
                 alt="Kinder- und Jugendrechte Song Contest 2022"/>
          <br>
          <p>Uns erreichten sage und schreibe 23 Einreichungen, über die wir uns riesig gefreut haben. Die Auswahl fiel
            uns
            nicht leicht, entscheiden mussten wir uns dennoch. Unsere Jury kürte acht Siegersongs, die am 15.11.2022
            einem
            begeisterten Publikum im OVAL – Die Bühne im Europark präsentiert wurden.</p>

          <v-carousel show-arrows-on-hover height="auto">
            <v-carousel-item :src="require('@/assets/bilder/1_Moderation Andrea und Christoph.jpg')">
            </v-carousel-item>
            <v-carousel-item :src="require('@/assets/bilder/2_Wir Vermissn VS 1 Saalfelden.jpg')">
              <ImageCaption><b>WIR VERMISSN</b> – VS 1 Saalfelden</ImageCaption>
            </v-carousel-item>
            <v-carousel-item
                :src="require('@/assets/bilder/3_The Nature is in danger Felix Hehenberger und Alexander Feldinger.jpg')">
              <ImageCaption><b>THE NATURE IS IN DANGER</b> – Felix Hehenberger & Alexander Feldinger</ImageCaption>
            </v-carousel-item>
            <v-carousel-item :src="require('@/assets/bilder/4_Is there any hope Paulina Macheiner.jpg')">
              <ImageCaption><b>IS THERE ANY HOPE?</b> – Paulina Macheiner (BG Tamsweg)</ImageCaption>
            </v-carousel-item>
            <v-carousel-item :src="require('@/assets/bilder/5_Reflect Niclas Wendlinger und Markus Nguyen.jpg')">
              <ImageCaption><b>REFLECT</b> – Niclas Wendlinger & Markus Nguyen</ImageCaption>
            </v-carousel-item>
            <v-carousel-item :src="require('@/assets/bilder/6_Schön wie du bist MRS Freilassing.jpg')">
              <ImageCaption><b>SCHÖN WIE DU BIST</b> – Sarah Augustin und Klasse (MRS Freilassing)</ImageCaption>
            </v-carousel-item>
            <v-carousel-item :src="require('@/assets/bilder/7_Whats wrong with you Gülcan.jpg')">
              <ImageCaption><b>WHAT'S WRONG WITH YOU</b> – Guelcan</ImageCaption>
            </v-carousel-item>
            <v-carousel-item :src="require('@/assets/bilder/8_Wir Team Seref MS Mosshammer Bischofshofen.jpg')">
              <ImageCaption><b>WIR</b> – Team „Seref“, MS Mosshammer Bischofshofen</ImageCaption>
            </v-carousel-item>
            <v-carousel-item
                :src="require('@/assets/bilder/9_Zusammen sind wir stark Elias Emil Leo Linda Maximilian Jeeli.jpg')">
              <ImageCaption><b>ZUSAMMEN SIND WIR STARK</b> – Elias, Emil, Jeeli, Leo, Linda, Maximilian</ImageCaption>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12 songs">
          <h2>Und das sind die Sieger:innen mit ihren Songs</h2>
          <p><b><a target="_blank" :href="require('@/assets/songs/Zusammen sind wir stark.wav')">ZUSAMMEN SIND WIR STARK*</a></b> – Elias, Emil, Jeeli, Leo, Linda, Maximilian</p>
          <p><b><a target="_blank" :href="require('@/assets/songs/Schön wie du bist.mp3')">SCHÖN WIE DU BIST</a></b> – Sarah Augustin und Klasse (MRS Freilassing)</p>
          <p><b><a target="_blank" :href="require('@/assets/songs/Wir vermissen.mp3')">WIR VERMISSN</a></b> – VS 1 Saalfelden</p>
          <p><b><a target="_blank" :href="require('@/assets/songs/Nature is in danger SF rough Mix.mp3')">THE NATURE IS IN DANGER</a></b> – Felix Hehenberger & Alexander Feldinger</p>
          <p><b><a target="_blank" :href="require('@/assets/songs/Reflect (Niclas und Markus) Master SonicFlow final.mp3')">REFLECT*</a></b> – Niclas Wendlinger und Markus Nguyen</p>
          <p><b><a target="_blank" :href="require('@/assets/songs/Whats wrong with you.mp3')">WHAT’S WRONG WITH YOU</a></b> – Guelcan</p>
          <p><b><a target="_blank" :href="require('@/assets/songs/Wir.mp3')">WIR</a></b> – Team „Seref“ MS Mosshammer Bischofshofen</p>
          <p><b><a target="_blank" :href="require('@/assets/songs/is there any hope Mix SF 4.mp3')">IS THERE ANY HOPE?</a></b> Paulina Macheiner (BG Tamsweg)</p>
          <br>
          <p><i>Songs gemischt und gemastert von Wolfgang Schramml im Sonic Flow Studio Wals/Salzburg (Ausnahme*)</i></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="livestream">
          <h2>Das Konzert zum Nachsehen</h2>

          <video controls preload="none" :poster="require('@/assets/2022-Livestream-Thumbnail.png')">
            <source src="/20221115_KIJA%20Song%20Contest_FULL.mp4" type="video/mp4">
            <img :src="require('@/assets/2022-Livestream-Thumbnail.png')">
          </video>

          <p>Wir bedanken uns bei allen Kindern, Jugendlichen und Erwachsenen, die den Song Contest zu einem unvergesslichen Erlebnis gemacht haben.</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Rückfragen und Kontakt</h2>
          <p>Projektleitung 2022 Maria Jäger<br>
            <a href="mailto:kija@salzburg.gv.at">kija@salzburg.gv.at</a></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Ein Projekt von</h2>
        </v-col>
        <v-col cols="12">
          <v-row class="societies">
            <a href="https://www.kija-sbg.at" target="_blank">
              <img src="@/assets/societies/kija.svg"/>
            </a>
            <v-col cols="auto">
              <a href="https://www.akzente.net" target="_blank">
                <img src="@/assets/societies/akzente.png"/>
              </a>
            </v-col>
            <v-col cols="auto">
              <a href="https://verein-kinderrechte.at" target="_blank">
                <img src="@/assets/societies/vzfdk.svg"/>
              </a>
            </v-col>
            <v-col cols="auto">
              <a href="https://www.spektrum.at" target="_blank">
                <img src="@/assets/societies/spektrum.png"/>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <h2>Wir sagen danke</h2>
        </v-col>
      </v-row>
      <v-row class="sponsors">
        <v-col cols="auto">
          <a href="https://www.hoppolino.at" target="_blank">
            <img src="@/assets/sponsors/hoppolino.png"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://www.gut-aiderbichl.com" target="_blank">
            <img src="@/assets/sponsors/gut-aiderbichl.jpg"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://www.kitzsteinhorn.at/de/winter/maiskogel/maisiflitzer" target="_blank">
            <img src="@/assets/sponsors/maisiflitzer.png"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://schauspielhaus-salzburg.at" target="_blank">
            <img src="@/assets/sponsors/schauspielhaus.png"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://salzburg-zoo.at" target="_blank">
            <img src="@/assets/sponsors/zoo-salzburg.png"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://www.wildpark-ferleiten.at" target="_blank">
            <img src="@/assets/sponsors/wildpark-ferleiten.png"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://www.marionetten.at" target="_blank">
            <img src="@/assets/sponsors/smt.jpg"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://www.winterfest.at" target="_blank">
            <img src="@/assets/sponsors/winterfest.png"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://www.oval.at" target="_blank">
            <img src="@/assets/sponsors/europark.png"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://www.uniqa.at" target="_blank">
            <img src="@/assets/sponsors/uniqa.png"/>
          </a>
        </v-col>
        <v-col cols="auto">
          <a href="https://www.salzburg.gv.at" target="_blank">
            <img src="@/assets/sponsors/salzburg.png"/>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import BasicComponent from "@/global/BasicComponent";
import Component from "vue-class-component";
import Jury from "@/components/Jury";
import ImageCaption from "@/components/ImageCaption.vue";

@Component({
  components: {ImageCaption, Jury}
})
export default class Home extends BasicComponent {

  get pastImages() {
    return [
      "@/assets/bilder/1_Moderation.jpg",
      "@/assets/bilder/2_Wir Vermissn VS 1 Saalfelden.jpg",
      "@/assets/bilder/3_The Nature is in danger Felix Hehenberger und Alexander Feldinger.jpg",
      "@/assets/bilder/4_Is there any hope Paulina Macheiner.jpg",
      "@/assets/bilder/5_Reflect Niclas Wendlinger und Markus Nguyen.jpg",
      "@/assets/bilder/6_Schön wie du bist MRS Freilassing.jpg",
      "@/assets/bilder/7_Whats wrong with you Gülcan.jpg",
      "@/assets/bilder/8_Wir Team Seref MS Mosshammer Bischofshofen.jpg",
      "@/assets/bilder/9_Zusammen sind wir stark Elias Emil Leo Linda Maximilian Jeeli.jpg",
    ];
  }

}
</script>

<style lang="scss" scoped>

@import "../style/global";

$headerHeight: 80vh;

.header-container {
  width: 100%;
  height: auto;
  //max-height: 100vw;
  position: relative;


  .lights-chain {
    height: 20vh;
    width: 100vw;
    background-image: url("../assets/lights-chain.svg");
    background-size: cover;
    background-repeat: revert;
    background-position-x: center;
  }

  /*
  .singers {
    height: 25vh;
    position: absolute;
    top: 5vh;
    left: 0;
    z-index: 0;
  }*/

  .enough-distance {
    width: 15vmax;
    position: absolute;
    top: 5vh;
    left: 5vh;
    z-index: 1;
  }

  .header-text {
    z-index: -1;
    min-width: 220vw;
    min-height: 55vh;
    max-height: 80vh;
    margin-top: -10vh;
    margin-bottom: -3vh;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

  }
}

.banner {
  width: 100%;
  display: block;
  text-align: center;
  text-transform: uppercase;
  background-color: rgb(157, 159, 183);

  border-radius: 0.5em;

  color: white;
  font-weight: bold;
  font-size: 1.5em;
  padding: 0.5em 5vw 0.2em;

  @media screen and (max-width: 350px) {
    font-size: 1.3em;
  }
}

.project-short-description {
  font-style: italic;
  text-align: center;
}

.steps {
  margin-top: -2em;
  padding-left: 3em;

  p {
    border-color: $purple;
    margin: 0;
    padding: 16px 30px;
    counter-increment: section;
    position: relative;
  }

  p .v-icon {
    $size: 30px;
    height: $size;
    width: $size;
    //content: counter(section);
    position: absolute;
    right: 100%;
    transform: translate(-6px, -8px);
    margin-right: -20px;
    padding: 10px 10px 10px 10px;
    border-radius: 50%;
    background-color: $red;
    text-align: center;
    color: white;
    font-size: 110%;
    font-weight: bold;
  }

  p:nth-of-type(9) ~ p {
    .v-icon {
      padding-left: 5px !important;
    }
  }

  p {
    border-left: 2px solid black;
    margin-right: 30px;
    padding-right: 0;
  }

  p:nth-of-type(5) ~ p {
    border-left-style: dashed;
    border-left-color: $purple;

    .v-icon {
      background-color: $purple;
    }
  }
}

.songs {
  p {
    padding-left: 2em;
    a {
      color: $purple;
    }
  }
}

.livestream {
  video {
    width: 100%;
    cursor: pointer;
  }
}

.kija {
  max-height: 200px;
  max-width: 100%;
}

.societies {
  justify-content: space-between;
  align-items: center;
  margin: -12px 0 !important;

  img {
    max-height: 100px;
  }
}

.sponsors {
  background-color: white;
  justify-content: space-evenly;
  align-items: center;
  margin: -12px 0 !important;

  img {
    max-height: 70px;
    max-width: 30vw;
  }
}

</style>
