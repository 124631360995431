<template>
  <div class="imprint">
    <v-container>
      <v-row>
        <v-col cols="auto">
          <BackButton/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Impressum</h2>
          <p>
            Medieninhaberin, Herausgeberin: Kinder- und Jugendanwaltschaft Salzburg<br>
            Sitz: Fasaneriestraße 35, 1. Stock 5020 Salzburg<br>
            Telefon: 0662-430 550<br>
            E-Mail: <a href="mailto:kija@salzburg.gv.at">kija@salzburg.gv.at</a><br>
            Website: <a href="https://www.kija-sbg.at">www.kija-sbg.at</a><br>
            Rückfragen zum Song Contest: Maria Jäger, <a
              href="mailto:maria.jaeger@salzburg.gv.at">maria.jaeger@salzburg.gv.at</a><br>
            Für den Inhalt verantwortlich: Andrea Holz-Dahrenstaedt<br>
            <br>
            Ein Projekt der Kinder- und Jugendanwaltschaft Salzburg in Kooperation mit Akzente, Spektrum und dem Verein zur
            Förderung der Kinderrechte.<br>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BackButton from "@/components/BackButton";

@Component({
  components: {BackButton},
})
export default class Impressum extends Vue {

}
</script>
<style scoped lang="scss">
.imprint {
  line-height: 1.5;
}
</style>
<style>

</style>
