<template>
  <v-app id="app" data-app>
    <router-view/>
    <Footer/>
  </v-app>
</template>

<style lang="scss">
@import "style/global";

</style>
<script>
import Footer from "@/components/Footer";
import Component from "vue-class-component";
import BasicComponent from "@/global/BasicComponent";
@Component({
  components: {Footer}
})
export default class App extends BasicComponent {


}
</script>
<style lang="scss">
.v-application {
  background-color: transparent !important;
}

.v-container {
  background-color: transparent;
}
</style>
