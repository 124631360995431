<template>
  <v-col cols="12" md="6">
    <v-row class="jury">
      <div class="img-container" :style="'background-image: url(\'' + imageLink + '\');'">

      </div>
      <div class="data-container">
        <div class="data">
          <p class="quote" :class="{long: isLongQuote}">
            „{{ quote }}“
          </p>
          <span class="name">{{ name }}</span>
          <span class="description">{{ description }}</span>
        </div>
      </div>
    </v-row>
  </v-col>
</template>

<script>
import BasicComponent from "@/global/BasicComponent";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component
export default class Jury extends BasicComponent {

  @Prop(String) name;
  @Prop(String) description;
  @Prop(String) quote;

  get imageLink() {
    return require(`@/assets/jury/${this.name}.jpg`);
  }

  get isLongQuote() {
    return this.quote?.length > 200;
  }

}
</script>

<style lang="scss" scoped>
$imageSize: 150px;

.jury {
  display: flex;
  flex-wrap: wrap;
  padding: 1.2em;
  justify-content: center;
}

.img-container {
  height: $imageSize;
  width: $imageSize;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.data-container {
  flex: 1 1;
  padding-left: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quote {
  font-size: 1.2em;
  font-style: italic;

  &.long {
    //font-size: 1em;
  }
}

.name {
  display: block;
  font-weight: bold;
}

/*
.jury:nth-of-type(even) {
  .img-container {
    order: 1;
  }

  .data-container {
    padding-left: 0;
    padding-right: 2em;
  }
}
 */

@media screen and (max-width: 450px) {
  .img-container {
    order: 0 !important;
  }

  .data-container {
    flex: 1 1 auto;
    padding: 0;
    padding-top: 1em;
  }
}

</style>
