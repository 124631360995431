import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'vuetify/dist/vuetify.min.css'
import Vuetify from "vuetify";

import VueMatomo from "vue-matomo";

import "@/style/fonts.scss";

Vue.config.productionTip = false

const vuetify = new Vuetify(
        {
            options: {
                customProperties: true
            },
            theme: {
                themes: {
                    light: {
                        primary: '#ff6b3d',
                        secondary: '#75759c',
                        accent: '#000',
                        error: '#FF5252',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#FFC107'
                    }
                }
            },
            icons: {
                iconfont: "md"
            }
        }
    )
;

Vue.use(Vuetify);

/*
Vue.use(VueMatomo, {
    siteId: 5,
    host: "https://analytics.gngn.at",
    router: router,
    disableCookies: true,
});
 */

/*
Vue.use(VueGtag, {
    config: {
        id: "G-GGW7R5RTQJ",
        params: {
            anonymize_ip: true,
        },
        anonymize_ip: true
    },
    appName: "Musik macht Mut",
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to) {
        return {
            page_title: to.name,
            page_path: to.path,
        };
    },
}, router);
 */

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
