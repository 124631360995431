<template>
  <footer class="footer">

    <router-link :to="{name: 'Impressum'}">
      Impressum
    </router-link>
    <router-link :to="{name: 'Datenschutz'}">
      Datenschutz
    </router-link>
</footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.footer {
  max-width: 100vw;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 20px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    color: black;
    text-decoration: none;
    //text-transform: uppercase;
    font-weight: normal;

    margin-left: 10px;
    margin-right: 10px;

    &:visited {
      color: black;
    }
  }

  .recaptcha {
    $fontSize: 10px;

    font-size: $fontSize;
    margin-top: 10px;

    a {
      font-size: $fontSize;
      font-weight: 600;
    }
  }
}
</style>
