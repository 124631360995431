<template>
  <div class="imprint">
    <v-container>
      <v-row>
        <v-col cols="auto">
          <BackButton/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Datenschutz</h2>
          <h3>Cloudflare</h3>
          <p>
            Im Rahmen des Aufrufens der Website werden von unseren Servern automatisch einige Metadaten wie
            Geräte-Informationen oder IP-Adressen protokolliert. Für eine bessere Leistung setzen wir ein so genanntes
            Content Delivery Network (CDN), umgesetzt von <a href="https://www.cloudflare.com/security-policy"
                                                             target="_blank">Cloudflare</a>, Inc., 101 Townsend St, San
            Francisco, CA 94107,
            USA, ein. Mithilfe eines CDN werden Website-Komponenten zwischengespeichert und können somit schneller
            ausgeliefert werden. Ihre Verbindung zu unseren Servern wird somit durch die Server von Cloudflare geleitet.
          </p>
          <br>
          <h3>Cookies</h3>
          <p>Wir verwenden sogenannte Cookies, um unsere Seite nutzerfreundlich zu gestalten und kontinuierlich
            verbessern zu können. Cookies sind kleine Textdateien, die in Ihrem Browser gespeichert werden. Sie richten
            keinen Schaden auf Ihrem Gerät an.</p>
          <p>Cookies bleiben gespeichert, bis Sie diese löschen. Sie ermöglichen uns, Sie bei Ihrem nächsten Besuch
            wiederzuerkennen. Ist Ihnen das nicht recht, können Sie Ihren Browser so einrichten, dass er das Setzen
            Cookies standardmäßig verhindert und Sie dies im Einzelfall erlauben müssen.</p>
          <br>

          <!--
          <h3>Matomo Analytics</h3>
          <p>
            Diese Webseite verwendet <a href="https://matomo.org/">Matomo</a>, eine Open Source, selbstgehostete Software um anonyme Nutzungsdaten für diese Webseite zu sammeln.
          </p>
          <p>Die Daten zum Verhalten der Besucher werden gesammelt um eventuelle Probleme wie nicht gefundene Seiten,
            Suchmaschinenprobleme oder unbeliebte Seiten herauszufinden. Sobald die Daten (Anzahl der Besucher die
            Fehlerseiten oder nur eine Seite sehen, usw.) verarbeitet werden, erzeugt Matomo Berichte für die
            Webseitenbetreiber, damit diese darauf reagieren können. (Layoutveränderungen, neue Inhalte, usw.)</p>

          <p>Matomo verarbeitet die folgenden Daten:</p>
          <ul>
            <li>Cookies</li>
            <li>Anonymisierte IP-Adressen indem die letzten 2 bytes entfernt werden (also 198.51.0.0 anstatt 198.51.100.54)</li>
            <li>Pseudoanonymisierter Standort (basierend auf der anonymisierten IP-Adresse</li>
            <li>Datum und Uhrzeit</li>
            <li>Titel der aufgerufenen Seite</li>
            <li>URL der aufgerufenen Seite</li>
            <li>URL der vorhergehenden Seite (sofern diese das erlaubt)</li>
            <li>Bildschirmauflösung</li>
            <li>Lokale Zeit</li>
            <li>Dateien die angeklickt und heruntergeladen wurden</li>
            <li>Externe Links</li>
            <li>Dauer des Seitenaufbaus</li>
            <li>Land, Region, Stadt (mit niedriger Genauigkeit aufgrund von IP-Adresse)</li>
            <li>Hauptsprache des Browsers</li>
            <li>User Agent des Browsers</li>
            <li>Interaktionen mit Formularen (aber nicht deren Inhalt)</li>
          </ul>
          <iframe
              style="border: 0; height: 200px; width: 600px;"
              src="https://analytics.gngn.at/index.php?module=CoreAdminHome&action=optOut&language=de&fontFamily=sans-serif"
          ></iframe>
          -->

          <br>
          <h3>Sonstiges</h3>
          <p>
            Die Informationen zum Datenschutz von eingeschickten Songs finden Sie in den <a
              href="/Datenschutzerklaerung.pdf">Datenschutzbestimmungen</a>.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BackButton from "@/components/BackButton";

@Component({
  components: {BackButton},
})
export default class Impressum extends Vue {

}
</script>
<style scoped lang="scss">
.imprint {
  line-height: 1.5;
}
</style>
<style>

</style>
